.onboard-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 60px 8px 8px 8px;
    align-items: center;
    gap: 16px;
    position: relative;
    background: transparent;
    .previous-button {
        position: absolute;
        top: 0px;
        left: 0px;
        margin-right: auto;
        padding: 8px 16px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        background: transparent;

        color: #d62b1e;
        background: #efefef;
        border-radius: 4px;
        .icon {

        }

        .text {
            margin-top: 4px;
            font-weight: bold;
        }
    }
}