@use "scss/abstracts/variables.scss" as *;

.button-with-description-and-icon,
.link-button-with-description-and-icon {
    display: flex;
    align-items: center;
    gap: 16px;

    .icon-container {
        $color: #555;
        border: solid 2px $color;

        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        min-width: 50px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        & > * {
            color: $color;
        }
    }
    .texts {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        p,
        b {
            margin: 0;
            text-align: left;
        }

        b {
            font-size: 14px;
            color: #000;
            font-weight: bold;
        }
        p {
            color: #555;
            font-weight: 300;
            font-size: 12px;
        }
    }
    &.disabled {
        pointer-events: none;
        .icon-container {
            color: #bbb;
            border-color: #bbb;
            & > * {
                color: #bbb;
            }
        }
        .texts {
            b {
                color: #bbb;
            }
            p {
                color: #bbb;
                &.info {
                    color: $liveto-red;
                }
            }
        }
    }
}
.button-with-description-and-icon {
    background-color: transparent;
    padding: 0;
}
