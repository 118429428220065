@use "scss/abstracts/variables.scss" as *;

#start-onboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .ios-guide {
        text-align: center;
        padding: 8px;
        line-height: 24px;
        border: solid 1px #555;
        background: #fff;
        .icon {
            margin: 0 8px;
        }
    }
    .install-app {
        background: #d62b1e;
        color: #fff;
        padding: 16px 32px;
        border-radius: 100vh;
        font-size: 20px;
    }

    .continue-browser {
        font-size: 20px;
        text-decoration: underline;
    }

    .heading-container {
        margin: auto 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 32px;
        align-items: center;
        gap: 16px;

        img {
            width: 100%;
        }

        h2 {
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
        }


    }

    .language-select {
        margin: auto 0;
        display: flex;
        gap: 4px;
        flex-direction: column;
        width: 100%;
        max-width: 300px;

        select {
            padding: 8px 16px;
            background: rgba(#fff, .5);
            border: solid 1px #ccc;
            border-radius: 4px;
            width: 100%;
            max-width: 300px;
            font-size: 20px;

        }
    }

    .buttons-container {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 32px;
    }
}