.offline-status {
    position: fixed;
    top: -40px;
    left: 0;
    width: 100%;
    height: 32px;
    background: rgba(red,.8);
    z-index: 100;
    transition: top 1s cubic-bezier(1, 0, 0, 1);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &.show {
        top: 0px;
    }
}