.settings-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .content {
        padding: 80px 8px 8px 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;

    }
}