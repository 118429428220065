.scan-notification {
  position: absolute;
  z-index: 200;
  bottom: 2rem;
  right: 1rem;
  left: 1rem;
  &.error {
    background-color: #fa5252;
    .mantine-Notification-icon {
      background-color: #fff;
      color: #fa5252;
    }
    .mantine-Notification-body {
      > * {
        color: #fff;
      }
    }
    .mantine-Notification-closeButton {
      color: #fff;
    }
  }
  &.success {
    background-color: #40c057;
    .mantine-Notification-icon {
      background-color: #fff;
      color: #40c057;
    }
    .mantine-Notification-body {
      > * {
        color: #fff;
      }
    }
    .mantine-Notification-closeButton {
      color: #fff;
    }
  }
}
