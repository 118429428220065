#qr-reader-container {
    width: 100%;
    height: 100%;
    #camera-feed {
        width: 100%;
        height: 100%;
        background: #000;
        position: absolute;
        inset: 0;

        &.visible {
            visibility: visible;
        }

        &.hidden {
            visibility: hidden;

        }
    }

    .toggle-torch-button,
    .close-reader-button {
        z-index: 2;
        background: #fff;
        color: #000;
        padding: 16px;
        font-size: 18px;
        border-radius: 8px;
        position: absolute;

    }

    .close-reader-button {
        right: 16px;
        bottom: 40px;
    }
    .toggle-torch-button {
        right: 16px;
        bottom: 200px;
    }

    #reader-menu {
        width: 100%;
        height: 100%;
        background: #000;
        display: flex;
        padding: 8px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.visible {
            visibility: visible;
        }

        &.hidden {
            visibility: hidden;

        }

        h1 {
            color: #fff;
            pointer-events: none;
            font-size: 20px;
            text-align: center;
        }

        .error-text {
            color: red;
        }

        .icon {
            color: #fff;
            pointer-events: none;
        }
    }
}