@use "scss/abstracts/variables.scss" as *;

#home {
    padding: 8px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    background: transparent;
    height: 100%;

    h1 {
        text-align: center;
        margin: 16px 0;
        font-size: 20px;
        font-weight: bold;
    }

    .nav-button {
        width: 100%;
        padding: 16px;
        background: #fff;
        border: solid 1px #ccc;
        border-radius: 8px;
        font-size: 18px;
        display: flex;
        font-weight: bold;
        gap: 24px;
        align-items: center;
        color: #555;

        .button-icon {
            color: $liveto-teal;
        }

        .chevron {
            margin-left: auto;
        }

    }

    .button-container {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }
}