@use "scss/abstracts/variables.scss" as *;

#ticket-info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .heading {
        display: flex;
        align-items: center;
        padding: 16px 0;
        gap: 16px;

        button {
            background: transparent;
            display: flex;
            align-items: center;
            gap: 8px;
        }

        h1 {
            font-weight: bold;
            color: $liveto-red;
            font-size: 20px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px;
    }

    .ticket-data {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        
        .row-data {
            display: flex;
            flex-direction: column;
            gap: 8px;
            p {
                font-weight: bold;
            }

        }
        h2 {
            color: $liveto-red;
            font-weight: bold;
            font-size: 20px;
            letter-spacing: 1px;
        }
    }

}