@use "scss/abstracts/variables.scss" as *;

#booth-scan {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: 100%;
    padding-bottom: 0!important;
    .scanner {
        overflow: hidden;
        background: #000;
        width: 100%;
        height: 100%;
    }

    .status {
        width: 100%;
        border: solid 2px #ccc;
        border-radius: 8px;
        padding: 16px;
        text-align: center;
        &.read {
            background: $liveto-green;
            color: #fff;
        }
    }
}