#scan-mode-human-readable {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    position: absolute;
    top: 0px;
    background: #fff;
    width: 100%;
    z-index: 1;
    h2 {
        color: #000;
        font-size: 15px;
        font-weight: bold;
    }

    color: #000;

}