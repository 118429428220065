.setting-columns {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px;
    b {
        font-size: 14px;
        font-weight: bold;
    }

    small {
        font-size: 14px;
    }
    &-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        gap: 8px;
        padding: 8px 0;
    }
}
