@use "scss/abstracts/variables.scss" as *;

@mixin pulseAnimation($color: #fff) {
    animation: pulse 1s infinite alternate;

    @keyframes pulse {
        0% {
            transform: scale(1);
            box-shadow: 0 0 0 2px rgba($color, 0.8);
        }

        100% {
            transform: scale(1.02);
            box-shadow: 0 0 0 2px rgba($color, 0.3);
        }
    }
}

.scanner-scan {
    width: 100%;
    height: 100%;
    background: #000;
    padding: 0 !important;
    overflow: hidden;


    .actions {
        position: absolute;
        bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        z-index: 3;
        &.left {
            left: 0;
            right: unset;

            &>.action-button {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }

        &.right {
            right: 0px;
            left: unset;

            &>.action-button {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
        }
    }

    .action-button {

        width: 60px;
        height: 60px;
        background: #fff;
        color: $liveto-teal;


        &.on {
            &.torch {
                border: solid 2px $liveto-light-green;
                color: black;
                box-shadow: inset 0px 0px 5px 100px rgba(yellow, 0.8);

            }

            &.mute {
                border: solid 2px red;
                border-right: none;
                color: red;
                box-shadow: inset 0px 0px 5px 100px rgba($liveto-red, 0.5);
            }
        }

        &.close-reader {
            color: red!important;
        }

    }

    .last-sync-timestamp {
        position: absolute;
        bottom: 8px;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 8px;
        font-size: 12px;
    }
}

