@use "scss/abstracts/variables.scss" as *;

#scanner-event-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    padding-top: 16px;


    .event-name {
        padding: 8px;
        font-weight: bold;
        color: $liveto-red;
        font-size: 20px;
    }

    .products-ac-title {
        padding: 0px 8px;
        font-weight: bold;
    }
    .product {
        background: #fff;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .product-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-bottom: solid 1px #ccc;
        padding: 16px;

    }

    .product-name {
        color: $liveto-red;
        font-weight: bold;
        font-size: 18px;
    }

    .product-validity {
        font-size: 14px;
    }

    .variants {
        padding: 8px;
    }

    .variant-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .variant-title {
        font-weight: bold;
        display: flex;
        gap: 8px;
    }
    .variant-info {
        padding-top: 8px;
        padding-left: 32px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .access-control {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}