@use "scss/abstracts/variables.scss" as *;

#scan-list {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: inherit;
    padding-bottom: 0 !important;
    .scans-heading {
        display: flex;
        justify-content: space-between;
        padding: 16px;
        gap: 0.5rem;
        button {
            background: transparent;
            display: flex;
            align-items: center;
            gap: 8px;
        }
        h1 {
            font-weight: bold;
            font-size: 16px;
            color: $liveto-red;
        }
    }
    .scan-list-tabs {
        padding: 16px 8px;
        a {
            padding: 1rem;
            font-weight: 900;
            font-size: 16px;
            border: 2px solid $liveto-teal;
            border-radius: 5px;
            width: 100%;
            display: flex;
            justify-content: center;
            color: $liveto-teal;
            &:nth-child(odd) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:nth-child(even) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            &.active {
                background-color: $liveto-teal;
                color: $liveto-white;
            }
        }
    }
    .scan-search {
        width: 100%;
        padding: 16px 8px;
        box-shadow: -2px -3px 2px 3px rgba(#000, 0.1);
        display: flex;
        flex-direction: column;
        gap: 8px;

        label {
            letter-spacing: 1.1px;
            text-align: center;
        }
        input {
            width: 100%;
            padding: 12px;
            border: solid 1px #ccc;
            border-radius: 4px;
            text-align: center;
        }
    }

    .no-tickets {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 32px;
        gap: 8px;
        b {
            font-weight: bold;
            font-size: 18px;
        }
    }
    .products {
        width: 100%;
        overflow-y: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .grouped-event {
            display: flex;
            flex-direction: column;
            &-name {
                position: sticky;
                top: 0;
                font-size: 24px;
                font-weight: 700;
                background-color: #fafafa;
                z-index: 2;
                padding: 16px 8px 8px 8px;
            }
            &-list {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 16px 8px;
                .product {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    position: unset;
                    padding: 8px;
                    border-radius: 8px;
                    justify-content: space-between;

                    p {
                        text-align: left;
                    }
                    .info {
                        max-width: 80%;
                    }

                    .scan-amount {
                        text-align: left;
                        font-weight: bold;
                        font-size: 19px;
                        color: $liveto-red;
                        letter-spacing: 2px;
                    }

                    .product-name {
                        font-size: 12px;
                        margin: 8px 0;
                        font-weight: bold;
                        line-height: 1.3;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
    .scans {
        padding: 8px;
        width: 100%;
        overflow-y: auto;
        height: 100%;
        .list {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        .ticket {
            display: flex;
            align-items: center;
            gap: 16px;
            position: unset;
            padding: 8px;
            border-radius: 8px;

            .event {
                position: absolute;
                bottom: 0px;
                right: 7px;
                font-size: 10px;
                color: #555;
                display: none;
            }

            p {
                text-align: left;
            }

            .ticket-code {
                text-align: left;
                font-weight: bold;
                font-size: 19px;
                color: $liveto-red;
                letter-spacing: 2px;
            }

            .product-name {
                font-size: 12px;
                margin: 8px 0;
                font-weight: bold;
                line-height: 1.3;
                letter-spacing: 1px;
            }

            .icon {
                color: $liveto-teal;
            }
        }

        .filter-container {
            display: none;
            position: sticky;
            top: 0;
            display: flex;
            flex-direction: column;
            gap: 4px;

            select {
                padding: 8px;
                border-radius: 8px;
                background: rgba(#fff, 0.5);
            }
        }
    }
}

.lvt-input-text.search {
    position: fixed;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    background: #fafafa;
    padding: 12px 16px;
    z-index: 2;
    width: calc(50%);
    text-align: center;
    margin: 8px;
    box-shadow: 0px 0px 5px 5px rgba(red, 0.2);
}
