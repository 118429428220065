@use "scss/abstracts/variables" as *;

@use "scss/abstracts/variables" as *;
@use "scss/mixins/responsivity" as *;
//@import url("https://fonts.asdassda.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");



@font-face {
  font-family: "Roboto";   /*Can be any text*/
  src: local("Roboto Regular"),
    url("../fonts/Roboto-Regular.ttf") format("truetype");
}

$ff: "Roboto";


@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
  
}

body {
  overflow: hidden;
}

html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  font-family: $ff;
}

* {
  box-sizing: border-box;
}
#root {
  width: 100%;
  height: 100%;
  background-color: #efefef;
  & > * {
    margin: 0 auto;
    max-width: 500px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.modal {
  position: fixed;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  padding: 10px;

  .modal-content {
    position: relative;
    top: 10%;
    max-width: 1000px;
    width: 100%;
    min-width: 30vw;
    overflow: hidden;

    min-height: 30vh;
    height: 100%;
    max-height: 80vh;
    margin: 10px auto;
    background: #fafafa;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 10px;
  }
  .modal-close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    color: red;
    box-shadow: none;
    z-index: 10;
    &:hover {
      box-shadow: none;
    }
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999999999;
}

.react-datepicker-wrapper {
  width: auto;
  button {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
  &__input-container {
    display: flex;
    justify-content: flex-end;
  }
  .react-datepicker__header {
    background: #d62b1e;
    color: #fff;
  }
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker-time__header {
    color: #fff;
  }
  .react-datepicker__navigation-icon--next::before {
    border-color: #fff;
  }
}

.bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.overlay {
  position: absolute;
  inset: 0;
  z-index: 2;
  &--dark {
    background: rgba(0, 0, 0, 0.5);
  }
}

div {
  position: relative;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $liveto-pink;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $liveto-red;
}

@include responsive(phone) {
}
