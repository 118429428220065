#booth-contacts {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 32px 8px;

    h1 {
        font-size: 24px;
        font-weight: bold;
    }

    button {
        color: #fff;
        background: #d62b1e;
        padding: 24px;
        font-size: 18px;
        border-radius: 8px;
    }

    .contact {
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        h2 {
            font-size: 18px;
            font-weight: bold;
            color: #d62b1e;
            padding: 8px 0;
        }

        small {
            font-size: 12px;
        }

        .notes {
            border: solid 1px #000;
            padding: 8px;
            font-style: italic;
            border-radius: 4px;
        }
    }
}