@use "scss/abstracts/variables.scss" as *;

#home {
    padding: 8px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    background: transparent;
    overflow-y: auto;
    .chart-container {
        width: 100%;
    }


    .box-button {
        display: flex;
        gap: 16px;
        background: #fff;
        padding: 16px;
        align-items: center;
        box-shadow: 1px 1px 2px 2px rgba(#000, .1);
    }

    .box-button-icon {
        color: $liveto-teal;

    }

    .box-button-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        color: #555;

        h1 {
            font-size: 16px;
            margin: 0;
        }

        p {
            font-size: 12px;
            margin: 0;
        }
    }

    .in-numbers {
        gap: 16px;
        .info-row {
            align-items: flex-start;
            gap: 4px;
        }
        .info-row .label {
        }
        .info-row .value {
            font-weight: bold;
        }
    }

}

.chart-content {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 16px;
    padding: 0 8px 32px 8px;

    .chart-data {
        flex: 1 1 60%;
        display: flex;
        justify-content: center;

        .chart-data-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 8px;
            gap: 16px;
            justify-content: center;
        }
    }

    .chart-container {
        flex: 0 0 40%;
        max-width: 130px;
        position: relative;
        height: fit-content;
        margin: auto 0;
        .percent {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            font-size: 30px;
        }
    }

    .chart-p {
        text-align: left;
        text-transform: capitalize;
        letter-spacing: 1.2px;
        &.b {
            font-size: 24px;
            line-height: 1;
            font-weight: bold;
        }
    }
}