@use "scss/abstracts/variables.scss" as *;

.booth-header {
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px;
    z-index: 10;
    height: 60px;
    background-color: $liveto-teal;
    box-shadow: 0 0 5px 5px rgba(#000,.1);
    position: relative;
    .back-button {
        position: absolute;
        left: 8px;
        height: 100%;
        aspect-ratio: 1;
        background: transparent;
        color: #fff;
    }
    .booth-slogan {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        font-weight: bold;
    }

    small {
        font-size: 10px;
        color: #fff;
    }
}
