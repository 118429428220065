

.setting-group {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: #fafafa;
    border-radius: 8px;
    box-shadow: 3px 2px 5px 5px rgba(#ddd, .5);
}

.version-number {
    position: fixed;
    bottom: 8px;
    right: 8px;
}