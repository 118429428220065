.forward-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100vh;
    min-width: 75px;
    width: 75px;
    min-height: 75px;
    height: 75px;
    color: #fff;
    background: linear-gradient(145deg, #c1271b, #e52e20);
    box-shadow: 2px 2px 2px 2px rgba(#000,.2), inset 0px 0px 2px 5px rgba(#000,.05);
    margin-top: auto;
    &:disabled {
        opacity: .2!important;
    }
}