#device-info {
    .group-heading {
        color: #d62b1e;
        font-weight: bold;
        display: flex;
        gap: 16px;
        align-items: center;
        border-bottom: solid 1px #d62b1e;
        padding: 4px 0;
    }
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    b,
    p {
        margin: 0;
    }

    b {
        font-size: 14px;
        font-weight: bold;
    }

    p {
        font-size: 12px;
        font-weight: 300;
    }


}