#device-log {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .log-entry {
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: solid 2px #ccc;
        padding: 8px;
        gap: 16px;

        .entry-icon {
            color: #ddd;
        }
        .log-content {
            width: 100%;
            p {
                text-align: left;
            }
            & > * {
                margin: 0;
            }
            small {
                color: #555;
            }
        }
    }
}