.failed-scan {
    background: red;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;

    h1,
    p,
    b,
    .icon {
        color: #fff;
    }

    b {
        font-size: 18px;
        border: solid 1px #fff;
        padding: 8px;
        background: #000;
    }

    h1 {
        font-size: 28px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .close-status-button {
        margin-top: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 80px;
        max-height: 80px;
        width: 100%;
        height: 100%;
        font-size: 20px;

        &:disabled {
            opacity: 0.5;
        }
    }
}

.extra-info {
    background: #ddd;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    &-scannedtimes {
        color: red;
    }

    h1 {
        font-size: 28px;
    }

    .textarea-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 4px;
        textarea {
            width: 100%;
            border: solid 1px #ddd;
            padding: 8px;
            font-size: 16px;
            line-height: 1.5;
        }

        .small {
            margin-left: auto;
            width: fit-content;
        }
    }
    .buttons {
        margin-top: auto;
        display: flex;
        gap: 0.5rem;
        button {
            background: #d62b1e;
            color: #fff;
            padding: 24px;
            width: 100%;
            border-radius: 8px;
            font-size: 20px;
            &.accept {
                background: green;
            }
        }
    }
}
