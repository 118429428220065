@use "scss/abstracts/variables.scss" as *;

#scan-interrupt-modal {
    width: 100%;
    height: 100%;
    background: rgba(#ddd, 1);
    z-index: 10000;

    .content {
        width: 100%;
        height: 100%;
        position: relative;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow-y: auto;
        padding-bottom: 160px;
    }

    h1 {
        margin: 8px 0;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }

    .info-group {
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .ticket-info-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > * {
            font-size: 12px;
        }
    }

    .note {
        display: flex;
        flex-direction: column;
        gap: 4px;

        b {
            font-weight: bold;
        }
    }

    .actions {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 8px;
        box-shadow: 0 0 5px 5px rgba(#000, 0.2);
        background: #fff;

        input {
            border: solid 2px #ddd;
            padding: 8px;
            border-radius: 4px;
        }
    }

    .action-buttons {
        display: flex;
        gap: 8px;

        button {
            width: 100%;
            border: solid 1px #ddd;
            padding: 16px 8px;
            color: #fff;
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: center;

            &.accept {
                background: green;
            }

            &.accept-out {
                background: rgb(255, 115, 0);
            }

            &.decline {
                background: red;
            }
        }
    }
}
