#configuration-expired {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 16px;
    overflow-y: auto;

    .options {
        font-weight: bold;
        margin: 8px;
    }
    .retry,
    .delete {

        border: solid 1px #ddd;
        padding: 16px;
        width: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 4px;
        p {
            width: 100%;
            text-align: unset;
            b {
                font-weight: bold;
            }
        }
        button {
            width: 100%;
            border-radius: 8px;
            padding: 16px;
            border: none;
            box-shadow: 5px 5px 12px 1px rgba(#000, .2);
            &.retry {
                color: #fff;
                background: green;
            }


            &.delete {
                color: #fff;
                background: red;
            }
        }

        .fetch-status {
            color: red;
            font-size: 14px;
            font-weight: bold;
        }
    }





}