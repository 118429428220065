#load-page {
    width: 100vw;
    height: 100vh;
    background: #d62b1e;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    color: #fff;
}