.onboard-paragraph {
    line-height: 1.3;
    letter-spacing: .5px;
    text-align: center;
    font-size: 13px;
}

.onboard-next-tip {
    display: flex;
    gap: 8px;
    align-items: center;
    .next-tip {
        color: #555;
    }
}