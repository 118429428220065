.support-info {
    display: flex;
    width: 100%;
    padding: 8px 0;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    h3 {
        font-size: 15px;
        font-weight: bold;
        margin: 0;
    }
    .info {
        display: flex;
        gap: 4px;
        width: 100%;
        align-items: flex-start;

        p {
            font-size: 13px;
            text-align: left;
            margin: 0;
            width: 100%;
            line-height: 1.3;

        }

        .children {
            flex: 0 0 40%;
            display: flex;
            justify-content: flex-end;

        }

        &.column {
            flex-direction: column;
            gap: 8px;
            justify-content: flex-start;
            
            .children {
                width: 100%;
            }
        }
    }




}