@use "scss/abstracts/variables.scss" as *;

#refetch-heading {
  justify-content: space-between;
  height: 100%;
  .refetch-footer {
    display: flex;
    justify-content: center;
    width: 100%;
    .refetch-update {
      background: $liveto-green;
    }
  }
}

.refetch-heading {
  font-weight: 700;
  font-size: 20px;
}
.refetch-content {
  height: 100%;
  width: 100%;
}

.close {
  background-color: $liveto-red;
  padding: 0.5rem 1rem;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border-radius: 5px;
}

.data-info {
  display: flex;
  width: 100%;
  padding: 8px 0;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  h3 {
    font-size: 15px;
    font-weight: bold;
    margin: 0;
  }
  .column {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }
  .row {
    display: flex;
    align-items: center;
  }

  p {
    font-size: 14px;
    text-align: left;
    margin: 0;
    width: 100%;
    line-height: 1.3;
  }

  .children {
    flex: 0 0 40%;
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
  }

  &.column {
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;

    .children {
      width: 100%;
    }
  }
  &.refetch-status {
    align-items: center;
    padding-top: 2rem;
    h3 {
      text-align: center;
    }
  }
}
