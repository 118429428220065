@use "scss/abstracts/variables.scss" as *;

#quick-scan-options {
    position: absolute;
    width: 100%;
    height: 100%;

    left: -100%;
    transition: left 0.2s ease-in-out;
    z-index: 5;
    background: #fff;

    &.open {
        left: 0;
    }

    .close {
        position: absolute;
        right: 12px;
        top: 8px;
        display: flex;
        gap: 8px;
        align-items: center;
        background: red;
        color: #fff;
        padding: 8px;
        border-radius: 4px;
        z-index: 1;
    }

    .option-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 16px 8px 64px 8px;
        gap: 8px;
        overflow-y: scroll;

        .heading {
            font-weight: bold;
            font-size: 20px;
            width: 100%;
        }

        .ticket-code,
        .time-tolerance,
        .scan-mode,
        .expired-ticket-acceptance {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 32px 8px;
            gap: 8px;
            border-top: solid 2px #ddd;

            label {
                color: #555;
                font-weight: bold;
            }

            small {
                font-size: 13px;
                line-height: 1.2;
                color: #555;
                padding-bottom: 8px;
            }
        }

        .ticket-code {
            justify-content: center;
            align-items: center;
            height: 100%;
            margin-bottom: 6rem;
            label {
                color: #555;
                font-weight: bold;
                font-size: 20px;
            }
        }
        .section-switch {
            display: flex;
            width: 100%;
            gap: 8px;
        }

        .section-select {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 8px 0;
            gap: 4px;
            z-index: 1;
        }

        .scan-mode-button {
            width: 100%;
            padding: 16px;
            border: solid 1px #ccc;
            background: #fff;
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: 4px;
            border-radius: 4px;
            color: #555;
            cursor: pointer;

            &.active {
                background: rgba($liveto-red, 0.5);
                color: #000;
            }

            h1 {
                font-size: 16px;
                font-weight: bold;
            }

            p {
                font-size: 14px;
                letter-spacing: 0.7px;
                line-height: 1.2;
            }

            select {
                width: 100%;
                padding: 8px 16px;
            }
        }
    }

    .select-scan-type-button {
        background: $liveto-red;
        position: absolute;
        bottom: 32px;
        left: 8px;
        right: 8px;
        color: #fff;
        padding: 8px 16px;
        border-radius: 4px;
        margin-top: auto;
        font-size: 20px;
        z-index: 1;
        &:disabled {
            opacity: 0;
            pointer-events: none;
        }
    }
    .read-button {
        padding: 16px;
        background: $liveto-red;
        color: #fff;
        border-radius: 4px;
        width: 80%;
        font-size: 20px;
        z-index: 1;
        &:disabled {
            opacity: 0;
            pointer-events: none;
        }
    }
    .manual-input {
        width: 80%;
    }
}
