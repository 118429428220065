@use "scss/abstracts/variables.scss" as *;

.scanner-header {
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    z-index: 10;
    background-color: #fff;
    box-shadow: 0 0 5px 5px rgba(#000,.1);

    .scanner-slogan {
        font-size: 10px;
        font-weight: 500;
        color: $liveto-red;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        font-weight: bold;
    }

    small {
        font-size: 10px;
    }
}
