#user-settings {
    .reset-settings-button {
        background: transparent;
        border: none;
        font-weight: 600;
        font-size: 20px;
        color: #d62b1e;
        letter-spacing: 1.2px;
    }
    .save-changes {
        color: #fff;
        background: #d62b1e;
        padding: 8px 16px;
        border-radius: 4px;
        &:disabled {
            opacity: .3;
        }
    }

    .name-change-status {
        color: green;
        font-size: 12px;
        width: 100%;
        text-align: center;
    }

    .no-support {
        color: #d62b1e;
        font-size: 12px;
        text-align: center;
    }
}