@use "scss/mixins/responsivity.scss" as *;
@use "scss/abstracts" as *;

@mixin container() {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    @include responsive(largedesktop) {
        max-width: 1200px;
    }
    @include responsive(desktop) {
        max-width: 1000px;
    }

    @include responsive(laptop) {
        max-width: 800px;
    }
    @include responsive(tablet) {
        max-width: 100%;
        padding: 0 8px 0 calc($mainNavSize + 8px);
    }
    @include responsive(phone) {
        max-width: 100%;
    }
}

@mixin stickyTop($top: 0, $zIndex: 1) {
    position: sticky;
    top: $top;
    z-index: $zIndex;
}
