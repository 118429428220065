/** @format */

@use "scss/abstracts/variables.scss" as *;

#scanner-events {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	padding: 8px;
	.event-row {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 16px;
		background: #fff;
		padding: 16px 8px;
		border-radius: 8px;
		box-shadow: 0 8px 8px 0 rgba(85, 85, 85, 0.2);

		.event-image {
			width: 75px;
			min-width: 75px;
			height: 75px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-size: cover;
			overflow: hidden;
			border-radius: 8px;
			border: solid 1px #ccc;
		}
		.event-info {
			display: flex;
			flex-direction: column;
			gap: 8px;
			.event-name {
				font-size: 20px;
				color: #d62b1e;
				font-weight: bold;
			}
			.event-time {
				font-size: 14px;
			}
		}
	}
}
