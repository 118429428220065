#scanner-status {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 6;

    &.success {
        background: green;
    }

    &.failed {
        background: red;
    }

    &.scanned {
        background: yellow;
    }

    p,
    b,
    h1,
    small,
    .icon {
        color: #fff;
    }
    b {
        font-size: 28px;
        border: solid 1px #fff;
        padding: 8px;
        background: #000;
        text-align: center;
    }

    .ticket-valid-for  {
        text-align: center;
    }


    h1 {
        font-size: 28px;
        text-transform: uppercase;
        font-weight: bold;
    }

    small {
        padding: 16px 0 0 0;
    }

    .close-status-button {
        margin-top: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 80px;
        max-height: 80px;
        width: 100%;
        height: 100%;
        font-size: 20px;
    }
}