@mixin responsive($point) {
    @if $point == largedesktop {
        @media (min-width: 1920px) {
            @content;
        }
    } @else if $point == desktop {
        @media (max-width: 1400px) {
            @content;
        }
    } @else if $point == laptop {
        @media (min-width: 993px) and (max-width: 1200px) {
            @content;
        }
    } @else if $point == tablet {
        @media (min-width: 689px) and (max-width: 992px) {
            @content;
        }
    } @else if $point == phone {
        @media (max-width: 688px) {
            @content;
        }
    }
}
