$mainNavSize: 60px;
$contextMenuHeight: 60px;
$subContextMenuHeight: 40px;
$horizontalNavBarHeight: 40px;

// Colors
$liveto-red: #c4281a;
$liveto-pink: #ee8e88;
$liveto-black: #131313;
$liveto-text-gray: #555;
$liveto-dark-gray: #484848;
$liveto-gray: #c4c4c4;
$liveto-light-gray: #e4e4e4;
$liveto-white: #ffffff;
$liveto-teal: #00a3a8;
$liveto-turquoise: #ccedee;
$liveto-green: #8bc400;
$liveto-light-green: #5ee045;
$liveto-error-red: red;

$color-border: #ccc;

/* Font sizes*/
/* Replace these with $size ??*/
$fs-100: 12px;
$fs-200: 16px;
$fs-300: 18px;
$fs-400: 20px;
$fs-500: 28px;
$fs-600: 48px;
$fs-700: 54px;
$fs-title: 64px;
$fs-body: 17px;

/* Font weights*/
$weight-100: 100;
$weight-200: 200;
$weight-300: 300;
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;
$weight-900: 900;

$fw-light: 200;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: bold;

/* Sizes*/
$size-50: 0.25rem;
$size-100: 0.5rem; // 8px
$size-150: 0.75rem;
$size-200: 1rem; // 16px
$size-250: 1.25rem;
$size-300: 1.5rem; // 24px
$size-350: 1.75rem;
$size-400: 2rem; // 32px
$size-450: 2.25rem;
$size-500: 2.5rem; // 40px
$size-550: 2.75rem;
$size-600: 3rem; // 48px

/* Box shadow */
$boxShadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

$headerHeight: 60px;
