@use "scss/abstracts/variables.scss" as *;

.step-description {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .person {
        text-align: right;
        font-weight: bold;
        color: $liveto-red;
    }


    .notes {
        color: #000;
    }

    .timestamp {
        font-size: 12px;
        text-align: right;
        color: #000;
        font-weight: bold;
        letter-spacing: .9px;
    }
}

.scan-history-container {
    .show-more-history {
        padding: 24px;
        width: 100%;
        background: #d62b1e;
        color: #fff;
        border-radius: 8px;
        font-size: 18px;
    }
}

.step-row {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.step-notes {
    background: #fff;
    padding: 8px;
    color: #000;
    border: solid 1px $liveto-red;
}

.step-icon {
    padding: 8px;
    position: relative;
    width: 100%;
    height: 100%;

    &.entry {
        background: green;
        border: solid 2px green;
        border-radius: 4px;
    }

    &.exit {
        background: red;
        border: solid 2px red;
        border-radius: 4px;
    }

    &.section {
        background: #fff;
        border: solid 1px black;
        border-radius: 50%;

        &.entry {
            border: solid 1px green;
        }

        &.exit {
            border: solid 1px red;
        }
    }
}