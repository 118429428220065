@use "scss/abstracts/variables.scss" as *;




.card {



    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.3);

    background-color: #fff;

    padding: 0px;
    box-shadow: 0 8px 8px 0 rgba(#555, 0.2);
    &.pink {
        background: rgba($liveto-pink, 0.5);
        color: #555;

    }

    &.teal {
        background: rgba($liveto-teal, 0.5);
        color: #000;

    }

    &.red {
        background: rgba($liveto-red, 1);
        color: #fff;

    }

    &.white {
        background: rgba(#fff, 0.4);
        color: #555;

    }


    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 8px 16px;
    }

    .title {
        padding: 16px;
        width: 100%;

        //background: rgba(#fff, 0.2);
        h2 {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1.6px;
            text-transform: uppercase;
        }
    }
}