.device-name-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px;
    gap: 8px;
    input, select {
        padding: 8px;
        border: solid 2px #d62b1e;
        border-radius: 4px;
        background: transparent;
    }
}