#settings-header {
    width: 100%;
    height: 60px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 8px;

    box-shadow: 0 0 5px 5px rgba(#000, .1);
    z-index: 1;

    h1 {
        font-weight: bold;
        letter-spacing: .8px;
        font-size: 18px;
    }

    button {
        background: transparent;
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: 16px;
        line-height: 1.2;
    }
}