@use "scss/abstracts/variables.scss" as *;
@use "scss/mixins/responsivity.scss" as *;

#scanner-navigation {
    background: $liveto-white;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;

    @include responsive(tablet) {
        justify-content: space-between;
    }

    @include responsive(phone) {
        justify-content: space-between;
    }

    .navigation-section {
        display: flex;
        align-items: center;
        padding: 0px;
        height: 100%;
    }

    .scan-button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 100;
        font-size: 12px;
        width: 72px;
        height: 72px;

        .text {
            margin: 0;
        }
    }


    .navigation-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 4px;
        background: transparent;
        gap: 8px;
        border-bottom: solid 4px $liveto-white;
        letter-spacing: 0.8px;
        width: 15%;
        &.active {
            border-bottom: solid 4px $liveto-teal;
            color: $liveto-teal;
            font-weight: bold;

            .text {
                color: $liveto-red;
            }
        }

        .text {
            font-size: 10px;
        }


        &.scan {
            background: $liveto-red;
            color: $liveto-white;
            width: 72px;
            height: 72px;
            max-width: unset;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            box-shadow: 0px 0px 2px 2px rgba(#000, 0.5);

            gap: 0px;
            &.active {
                background: $liveto-green;
                color: $liveto-white;
                border: solid 2px #ccc;
                box-shadow: 0px 0px 5px 5px rgba(green, 0.5);
                .text {
                    color: $liveto-white;
                    font-weight: normal;
                }
            }
        }
    }
}